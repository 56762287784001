.btn-plus{
    color: #fff;
    cursor: pointer;
    margin-left: auto;
    width: 30px;
    height: 30px;
}

.btn-plus:hover{
    color:#ff9900; }



.project-card{
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #f3f3f3;
    color: #232f3e;
}

.edit{
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 10px;

}

.edit .add-icon {
    font-size: 25px;
    color: #232f3e;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .edit .add-icon:hover {
    color: #ff9900;
  }
  
  .slide-pane__close{
    margin-left: 0px !important;
  }
  .slide-pane__title-wrapper{
    margin-left: 0px !important;
  }

  .slide-pane__content{
    padding: 0px !important;
  }

  .searchBar{
    padding: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d2d2d2;
    display: flex;
    align-items: center;
  }

  .searchBar input{
    margin-left: 3px;
    width: 100%;
    border: none;
    outline: none;
  }


  .details-subtitle{
    margin-left: 10px;
    font-size: 12px;
    text-decoration: none;
    color: #2c3e50;
  }
  
  .company-name{
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    color: #2c3e50;
  }
  

  .details-title{
    text-transform: capitalize;
  }