.sidebarWorker {
}

.sidebarWorker .sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sidebarWorker .add-icon {
  font-size: 25px;
  color: #232f3e;
  cursor: pointer;
  margin-left: 10px;
}

.sidebarWorker .add-icon:hover {
  color: #ff9900;
}
