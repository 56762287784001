

.menu-select{
    color: #666666 !important;
    text-transform: capitalize !important;
}

.info-title{
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    
    text-align: left;
    padding: 10px;

}


.order-row-pdf .img {
    max-width:50px;
    max-height:50px;
    border-radius: 5px;
  }
  
  .order-row {
      display: flex;
      margin-top: 5px;
      margin-bottom: 5px;
      justify-content: center;
      align-items: center;
    }

    
  .order-row .img {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: #f3f3f3;
      margin-left: 10px;
    }
    
    .order-row .img img{
      max-width:50px;
      max-height:50px;
      border-radius: 5px;
    }
    
    .order-row .number{
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 5px;
    }
    
    .order-row .product-info{
      display: block;
      margin-left: 10px;
      width: 100%;
    }
    
    .order-row .btn-right{
      margin-left: auto;
      display: flex;
      align-items: center;
    }
    
    
  .order-row input{
      width:40px;
      font-size: 12px;
      border-radius: 5px;
    }
    
    .order-row .product-name{
      overflow: hidden;
      white-space: nowrap;
    }