.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232f3e;
}

.login-page .form-signin {
  width: 100%;
  max-width: 300px;
  padding: 20px;
  margin: 15px;
  border-radius: 10px;
  background-color: rgba(230, 230, 230, 1);

  box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.8);
}
.login-page .form-signin form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-page .form-signin .sousTitre {
  color: #232f3e;
  font-size: 16px;
  letter-spacing: 2px;
}

.login-page .form-signin form .input {
  margin-bottom: 20px;
}

/* styles.css */
.custom-textfield {
  margin-bottom: 20px !important;
}

/* Change text color */
.custom-textfield .MuiInputBase-input {
  color: #232f3e;
}

/* Change border color */
.custom-textfield .MuiOutlinedInput-notchedOutline {
  border-color: #232f3e !important;
}

/* Change placeholder color */
.custom-textfield .MuiInputLabel-outlined {
  color: #232f3e !important;
}

/* Change border color when selected */
.custom-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ff9900 !important;
}

/* Change placeholder color when selected */
.custom-textfield .MuiInputLabel-outlined.Mui-focused {
  color: #ff9900 !important;
}
