


.desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.logos{
    max-width: 300px;
    
    
}

.logos img{
    width: 100%;
    height: auto;
}
