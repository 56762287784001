.dock {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(230, 230, 230, 1);
  border-top: thin solid #dfdfdf;
  box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center; 
  align-items: center;
  z-index: 1000;
}

.dock-items {
  display: flex;
  justify-content: center; /* Center items within their container */
  align-items: center;
  flex-direction: row;
  width: fit-content;
}

.dock .logo {
  margin-left: 20px;
}
.dock .logo img {
  height: 20px;
}

.dock .separator {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 2px;
  min-height: 30px;
  background-color: #232f3e;
}

.dock-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #232f3e;
  font-size: 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  margin: 5px;
  position: relative;
}

.dock-item img {
  width: 30px;
  height: 30px;
}

.dock-item:hover {
  color: #ffffff;
  background-color: rgba(35, 47, 62, 0.7);
}

.dock-item .btn-title {
  position: absolute;
  bottom: 50px;
  background-color: #232f3e;
  padding: 5px;
  border-radius: 5px;
}

.user-menu {
  position: absolute;
  bottom: 50px;
  left: 0;
  background-color: #232f3e;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  min-width: 150px;
}

.user-menu a {
  text-decoration: none;
  color: #ffffff;
}

.user-menu-item {
  color: #ffffff;
  display: flex;
  padding: 5px;
  margin: 3px;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
}

.user-menu-item:hover {
  background-color: #ffffff;
  color: #232f3e;
  border-radius: 5px;
}

.user-menu-item .icon {
  font-size: 20px;
}

.user-menu .horizontal-separator {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 100%;
  min-height: 2px;
  background-color: #ffffff;
}

.dock .search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #232f3e;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
}

.dock .search-bar input {
  border: none;
  background: none;
  width: 120px;
}
.dock .search-bar input:focus {
  outline: none; /* This removes the default focus outline */
  border: none; /* This removes the border when the input is focused */
}
