.card-order{
    cursor: pointer;
    color:#4d5d73;
    margin-top: 0px;
    padding: 10px;
}
.card-order:hover{
    box-shadow:2px 2px 10px 10px #d2d2d2;
}

.card-order .card-subtitle{
    font-size: 12px;
    display: flex;
    align-items: center;
}

.card-order .card-title{
    font-size: 14px;
    text-transform: capitalize;
    
    border-radius: 5px;
    margin-bottom: 5px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.card-order .card-title2{
    font-size: 12px;
    text-transform: uppercase;
    
    border-radius: 5px;
    margin-bottom: 5px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background-color:#1f252e;
    color:#f3f3f3;
    padding: 10px;
}


.card-order img {
  width: 100%;
  border-radius: 10px;
}

.card-order .dot{
    width: 12px;
}

.card-order .small-icon{
    font-size: 14px;
    margin-right: 3px;
}
