

.details-title{
    background-color: #232f3e;
    color: #f3f3f3;
    font-size: 14px;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
}

.details-subtitle{
    display: flex;
    align-items: center;
    margin-top: 3px;
}

.details .small-icon{
    margin-right: 3px;
}

.details .dot{
    width: 12px;
}
.details .imgUser{
    text-align: center;
}
.details .imgUser img{
    border-radius: 10px;
    width: 90%;
}

.details-general{
    padding: 5px 10px;
    font-size: 14px;
    color: #232f3e;
    margin-top: 10px;
}

.details-address{
    padding: 5px 10px;
    font-size: 14px;
    color: #232f3e;
}

.details-address .details-label{
    font-size: 16px;
    color: #999;
}

.details .addressCard{
    padding:5px;
    font-size: 12px;
}

.details .addressCard .name{
    font-size: 14px;
    
    color:#666;
    margin-bottom: 3px;
    
}