
.company-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.company-card:hover{
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.company-card .image{
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.company-card img{
    width: 90%;
    object-fit: cover;
    border-radius: 10px;
}

.company-icon .img{

}

.company-icon .img img{
    width:100%;
}