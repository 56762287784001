.card-project{
    cursor: pointer;
    color:#4d5d73;
    margin-top: 0px;
    padding: 10px;
    position: relative;
    transition: all 0.3s ease;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.card-project:hover{
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.card-project .card-subtitle{
    font-size: 12px;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
}
.card-project .card-title{
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 5px;
}

.card-project .card-title2{
    font-size: 12px;
    text-transform: capitalize;
    
    border-radius: 5px;
    margin-bottom: 5px;
    background-color: #4d5d73;
    padding: 5px;
    color: #f3f3f3;
    margin-top: 5px;
}

.card-project .img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    aspect-ratio: 1;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}
.card-project img {
    
    max-width: 100%;
    max-height: 100%;
}

.card-project .card-line{
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color:#2c3e50;
}

.card-project .fake{
    position:absolute;
    width:40px;
    right:10px;
    top:10px;
}
.card-project .dot{
    width: 12px;
}

.card-project .small-icon{
    font-size: 14px;
    margin-right: 3px;
}


.card-list .info{
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.card-list{
    cursor: pointer;
    color:#4d5d73;
    margin-top: 0px;
    display: flex;
}
.card-list:hover{
    box-shadow:2px 2px 10px 10px #d2d2d2;
}

.card-list .card-subtitle{
    font-size: 12px;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.card-list .card-title{
    background: none !important;
    color: #2c3e50;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    border-radius: 5px;
    padding-left: 0px;
}

.card-list .img {
    width: 75px;
    min-width: 75px;
    
    border-radius: 10px;
    padding: 10px;
    margin: 0px;
  }
  
.card-list img {
  width: 100%;
  padding: 0px;
  margin: 0px;
  height: 100%;
  aspect-ratio: 1;
}

.card-list .dot{
    width: 12px;
}

.card-list .remove-button{
  position: absolute;
  right:10px;
  top:10px;
  color:red;
}
.card-list .remove-button:hover{
  color:#999;
}

