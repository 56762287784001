.btnMenu{
    float: left;
    margin-left: 10px;
    height: 25px;
    line-height: 21px;
    letter-spacing: 2px;
    margin-top: 7px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    font-weight: bold;
    border:1px solid #232f3e;
    border-radius: 5px;
    font-size: 12px;
}
.btnMenu:hover{
    color: #999;
    border:1px solid rgba(200, 200, 200, 1);
}

.menuAddanDelete{
    width: 100%;
    height: 40px;
    background-color: rgba(200, 200, 200, 0.5);
}
.hiddenInput{
    display: none;
}

.detailTitle{
    margin-top: 15px;
    font-size: 22px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: bold;
}


.input90{
    width: 100%;
    margin-top: 5px;
}
.input70{
    width: 70%;
    margin-top: 5px;
}
.input50{
    width: 50%;
    margin-top: 5px;
}
.descriptiontext{
    width: 100%;
    resize:none;
    height:100px;
    border-color: #ddd;
    border-radius: 5px;
}

.pointVert{
    margin-left: 3px;
    color:greenyellow;
}
.pointRouge{
    margin-left: 3px;
    color:red;
}   

.price-box{
    background-color: #232f3e;
    padding: 15px;
    border-radius: 10px;
}

.price-box .title{
    color: #f3f3f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.price-box .sub-title{
    color: #f3f3f3;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
}

.price-box .data{
    color:#61dafb;
    letter-spacing: 2px;
}

.price-box .location-title{
    color:#f3f3f3;
    text-align: center;
}

