
.card-item{
    cursor: pointer;
    color:#f3f3f3;
    width: 100%;
  }
  .card-item:hover{
  box-shadow:2px 2px 10px 10px #d2d2d2;
  }
  .card-title{
    background-color: #232f3e;     
    color: #f3f3f3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    padding: 5px;
  }
  .card-dispo{
    color: #232f3e;
    display: flex;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
  }
  .card-item img{
    width:100%;
  }

  .card-item-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 0; /* Set initial height to 0 */
  padding-top: 100%; /* Create a square by setting padding-top to 100% */
  position: relative; /* Required for absolute positioning */
}

.card-item-img img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Change this from 'cover' to 'contain' */
  position: absolute; /* Position the image within the square container */
  top: 0;
  left: 0;
}


  
.card-product-info{
  margin-top: 20px;
  padding: 20px;
}

.cardProductInfo h3{
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  letter-spacing: 2px;
  font-size: 32px;
}
.card-product-info .card-header{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.card-product-info .card-sub-header{
  margin-top: 10px;
  background-color: #232f3e;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:#f3f3f3;
  font-size: 12px;
}

.disponible{
  float: left;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
}


.montantLocation{
  display: block;
  text-align: center;
  height:150px;
  font-weight: 900;
  font-size: 22px;
  line-height: 150px;
  text-transform: uppercase;
  background-color: #232f3e;
  color: #fff;
  border: thin solid #fff;
  border-bottom: none;
  border-radius: 10px 10px 0px 0px;
}
.tarif{
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background-color: #465363;
  text-transform: uppercase;
  color: #fff;
  border: thin solid #fff;
}
.tarifTitre{
  border: thin solid #fff;
  background-color: #ff9900;
  color: #232f3e;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-top: none;
}

.valeur{
  border: thin solid #fff;
  background-color: #465363;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 2px;
  padding-top: 50px;
}

.card-line{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #f3f3f3 ;
  margin-bottom: 10px;
}
.card-line .info{
  color:#f3f3f3;
  width:100px;
  display: flex;
  align-items: center;
   text-align: right;
   justify-content: end;
}
.description-card{
  padding: 10px;
  background-color: rgba(35, 47, 62, 0.6) !important;
  color: #f3f3f3 !important;
  margin-left: 10px;
  margin-right: 10px;
}
.description-card .title{
  color:#f3f3f3;
  text-align: center;
  margin-bottom: 5px;
}

.info-card{
  position: relative;
  padding: 10px;
  background-color: rgba(35, 47, 62, 0.6) !important;
  color:#f3f3f3 !important;
}
.info-card .title{
  color:#f3f3f3;
  text-align: center;
  margin-bottom: 5px;
}

.dimensions {
  position: absolute;
  top: 10px; /* Adjust the top position as needed */
  right: 5px; /* Adjust the right position as needed */
  width: 50px; /* Set the width to make it small */
  font-size: 12px; /* Adjust the font size as needed */
}
.dimensions select{
  background: none;
  border-color: #f3f3f3;
  color:#f3f3f3;
  border-radius: 5px;
  padding: 2px;
}
.dimensions select option{
  color:#232f3e;
}

.small-input{
  width: 40px;
  padding: 5px; 
  border-radius: 5px;
  border: thin solid #f3f3f3;
  color:#f3f3f3;
  background-color: #232f3e;  
  font-size: 12px;
}

.small-input-light{
  width: 40px;
  padding: 5px; 
  border-radius: 5px;
  border: thin solid #f3f3f3;
  color:#f3f3f3;
  font-size: 12px;
  background: none;
}
.description-input{
  width: 90%;
  height: 100px;
  padding: 5px;
  border-radius: 5px;
  border: thin solid #f3f3f3;
  color:#f3f3f3;
  text-align: center;
  font-size: 12px;
  resize: none;
  background: none;
}
.medium-input-light{
  width: 80px;
  padding: 5px; 
  border-radius: 5px;
  border: thin solid #f3f3f3;
  color:#f3f3f3;
  font-size: 12px;
  text-align: end;
  background: none;
}

.big-input{
  width:100%;
  text-align: center;
  font-weight: bold;
  padding: 5px; 
  border-radius: 5px;
  border: thin solid #232f3e;
  color:#232f3e;
  font-size: 12px;
}



.checkboxList {
  display: block;
  width: 100%;
  height: 250px;
  overflow: auto;
  border-top: solid 1px #999999;
  border-bottom: solid 1px #999999;
}

.cardCheckboxList {
  margin-top: 20px;
  border: solid 1px #999999;
}

.cardProductInfo {
  margin-top: 20px;
}



.inputCheck {
  height: 40px;
  line-height: 40px;
  padding-right: 10px;
  border-bottom: solid 1px #eeeeee;
}

.inputCheck label {
  padding-left: 10px;
}

.inputCheck input {
  margin-right: 10px;
}

.inputCheck:hover {

  background-color: rgba(200, 200, 200, 0.5);
}

.active {
  background-color: rgba(200, 200, 200, 0.5);
}


.inputCategory {
  text-decoration: none;
  font-size: 14px;
  height: 30px;
  width: 100%;
  border: thin solid #999999;
  border-radius: 5px;
  background: none;

}



.selectBox select{
    padding: 0.375rem 0.75rem;
    margin: 0;
    border: none;
    border-radius: 0.25rem;
    background-color: #f8f9fa;
    color:#434343;
  
}
