


.log-card{
    border: solid thin #e0e0e0;
    border-radius: 10px;
    padding: 10px;
}

.log-card-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.log-card-user{
    display: flex;
    align-items: center;
    
    color:#232f3e;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
}
.log-card-user .img{
    width: 33%;
    margin-right: 10px;
    
}

.log-card-user .img img{
    border-radius: 5px;
    width: 100%;
    aspect-ratio: 1/1;
}

.log-card-action{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 5px;
}