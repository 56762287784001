

.card-list-settings{

}

.card-list-settings .title{
    background-color: #232f3e;
    color: #f3f3f3;
    font-size: 12px;
    text-align: center;
    padding: 10px;
}
.card-list-settings .content{
 height:200px;
 overflow-y: auto;
}

.card-list-settings .content .tag{
    padding: 10px;
    font-size: 12px;
    cursor: pointer;
   }
   .card-list-settings .content .tag:hover{
    background-color: rgba(100,100,100,0.5)
   }
   .card-list-settings .content .active{
    background-color: rgba(100,100,100,0.5)
   }
   
   .card-list-settings .add-icon {
    font-size: 25px;
    color: #f3f3f3;
    cursor: pointer;
  }
  
  .card-list-settings .add-icon:hover {
    color: #61dafb;
  }

.card-list-settings .footer{
    padding: 5px;
    background-color: #232f3e;
    color: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-list-settings .footer .btn{
    color:#ddd;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.card-list-settings .footer .btn:hover{
    color:#ff9900;
}


