.imgUser{
    text-align: center;
}
.imgUser img{
    border-radius: 10px;
    width: 90%;
}

.add-poste{
    cursor: pointer;
    
}

.add-poste:hover{
    color: #888888;
}

.codeSecurityDigit
{
    font-size: 1.5em;
    font-weight: bold;
    color: #000;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    border: solid thin #888888;
    margin-right: 10px;
    width:35px;
}
.details-subtitle{
    font-size: 18px;
    margin-left: 5px;
}


.dot-big{
    width:20px;
}

.details {
    padding:0px;
}

.addressCard{
    padding:5px;
    font-size: 14px;
    margin-top: 10px;
}

.addressCard .main-name{
    font-size: 18px;
    letter-spacing: 2px;
    text-align: center;
    font-weight: bold ;
    color:#232f3e;
    margin-bottom: 3px;
    
}

.addressCard .sub-name{
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 3px;
    
}


