.imgBox{
  margin-top: 20px;
  border: none !important;
}
.uploadPopup{
  position: fixed;
  background-color: #eeeeee;
  border-radius: 5px;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
  border: thin solid #dddddd;
  padding: 10px;
  display:none;
}
.progressTitle{
  margin-bottom:  10px ;

}

.progress{
  margin-bottom: 10px;
}

.imgProgressBar{
  height:40px;
  width:300px;
}

.imgBig{
  display: block;
  width: 100%;
  overflow: hidden;
  
  
}
.imgBig img{
  width:100%;
 
}
.imgSmall{
  display: inline-flex;
  /*border-top: thin solid rgba(120,120,120, 0.5);*/
  width: 100%;
  
  padding: 2px;
  overflow-x: scroll;
  overflow-y: hide;
  margin: 0px;
  background-color: rgba(240, 240, 240, 0.5);
}
.imgSmall img{
  display:inline;
  margin: 5px;
  padding: 5px;
  min-width: 85px;
  min-height: 85px;
  max-width: 85px;
  max-height: 85px;
  border-radius:5px;
  cursor: pointer;
 }
.imgSmall img:hover{
  border:solid thin #666666;
  box-shadow: inset 0px 0px 3px 3px rgba(9,121,190, 0.5);
}

.imgSmall .active{
  border:solid thin #666666;
  box-shadow: inset 0px 0px 3px 3px rgba(9,121,190, 0.5);
}

