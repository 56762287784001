

.sidebar-dashboard{
    background-color: rgba(230 , 230, 230, 1);
    width: 100%;
    box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 200px;
}

.sidebar-dashboard .sidebar__item{
    padding: 10px;
    border-bottom: thin solid #dfdfdf;
    cursor: pointer;
    color:#333;
    align-items: center;
    display: flex;
}

.sidebar-dashboard .sidebar__item:hover{
    background-color: #232f3e;
    color: white;
}

.sidebar-dashboard .btn-icon{
    margin-right: 5px;
    margin-left: 10px;
    height: 20px;
}

.dashboard-container {
    height: 100%;
    width: 100vw;
    padding: 0px;
    margin: 0px;
    display: flex;
    /* Set flex direction to row to have sidebar and content side by side */
    flex-direction: row;
    overflow: hidden;
  }
  

  .info-box{
    background-color: #232f3e;
    border-radius: 10px;
    margin-top: 50px;
  }
.info-line{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    }

.chart-box {
    max-width:380px;
    margin: auto;
}
.subTitle{
    margin-top: 20px;
    font-size: 16px;
    display: block;
    color: #333;
    padding-bottom: 10px;
    border-bottom: thin solid #232f3e;
    margin-bottom: 20px;
}

.sidebar-dashboard .active{
    background-color: #232f3e;
    color: white;

}