/* scrollToTop.css */

.scroll-to-top {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 1000;
}

.scroll-to-top button {
  background-color: #181a1c;
  background-color: rgba(24, 26, 28, 0.6);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  
}

.scroll-to-top button.show {
  visibility: visible;
  opacity: 1;
  
  
}


.scroll-to-top button:hover {
  background-color: rgba(128, 128, 0, 0.6);
}
