@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

body {
  width: 100vw;
  height: 100vh;

  background-color: #ffffff;
  background: linear-gradient(to right, #eeeeee, #eeefff, #ffffff);
  font-family: 'Oswald', serif !important;
  margin: 0px;
  padding: 0px;
  color: #232f3e !important;
  overflow: hidden;
}

.main-content {
  margin: 0px;
  width: 100vw;
  height: calc(100vh - 50px);
  overflow-y: auto;
  padding-bottom: 20px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noDecoration {
  text-decoration: none;
  color: #232f3e;
}
.noDecoration:hover {
  text-decoration: none;
  color: #999;
}
.btn-secondary{
  display: flex;

}
.btn-secondary .icons{
  cursor: pointer;
}
.btn-secondary .icons:hover{
  color:#999 !important;

}

.heightMin60{
  margin-bottom: 50px;
}

.small-header {
  background-color: #4d5d73;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.small-header .title {
  font-size: 20px;
  color: #f3f3f3;
}


.small-header .add-icon {
  font-size: 25px;
  color: #f3f3f3;
  cursor: pointer;
  margin-left: 10px;
}

.small-header .add-icon:hover {
  color: #61dafb;
}


.header {
  background-color: #232f3e;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.header .title {
  font-size: 20px;
  color: #f3f3f3;
}
.header .sub-title {
  font-size: 12px;
  color: #61dafb;
}

.header .add-icon {
  font-size: 25px;
  color: #f3f3f3;
  cursor: pointer;
  margin-left: 10px;
}

.header .add-icon:hover {
  color: #61dafb;
}

.sub-header {
  background-color: #1f252e;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f3f3;
  font-size: 12px;
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
}
.sub-header .title {
  font-size: 16px;
}
.sub-header .sub-title {
  color: #999;
}

.sub-header .add-icon {
  font-size: 25px;
  color: #f3f3f3;
  cursor: pointer;
  margin-left: 10px;
}

.sub-header .add-icon:hover {
  color: #ff9900;
}

.btn-primary {
  background-color: #232f3e !important;
  color: #f3f3f3 !important;
}
.btn-primary:hover {
  color: #61dafb !important;
}
.green-dot {
  min-width: 15px;
  min-height: 15px;
  background-image: url(./assets/images/green-dot.png);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 10px;
}

.red-dot {
  min-width: 15px;
  min-height: 15px;
  background-image: url(./assets/images/red-dot.png);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 10px;
}

.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #232f3e;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
}

.search-bar input {
  border: none;
  background: none;
  width: 120px;
}
.search-bar input:focus {
  outline: none; /* This removes the default focus outline */
  border: none; /* This removes the border when the input is focused */
}
.separatorHorizontal {
  width: 100%;
  background-color: #232f3e;
  border-radius: 5px;
  margin-top: 20px;
  height: 4px;
}

.mini-card {
  cursor: pointer;
  color: #4d5d73;
  margin-top: 0px;
  padding: 5px;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    
}

.card-date{
  color: #4d5d73;
  margin-top: 0px;
  padding: 5px;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.card-date .info {
  padding-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}


.card-date .img {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  max-width: 80px;
  max-height: 80px;
}

.card-date .img img {
  max-width: 100%;
  max-height: 100%;
}


.card-date .info .card-subtitle{
  font-size: 14px;
  text-transform: lowercase;
  font-weight: bold;
  display: flex;  
  height: 100%;
  align-items: center;
  
}

.mini-card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}



.mini-card .img {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  max-width: 80px;
  max-height: 80px;
}

.mini-card .img img {
  max-width: 100%;
  max-height: 100%;
}

.mini-card .info{
  padding-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.mini-card .info .card-title{
  font-size: 14px;
  text-transform: capitalize;
  font-weight: bold;
  border-radius: 5px;
  text-align: left;
  background: none;
  color:#232f3e;
  padding: 0px;
  margin: 0px;
  margin-left: 5px;
}



.mini-card .info .card-subtitle{
  font-size: 12px;
  text-transform: lowercase;
  display: flex;  
  align-items: center;
}

.mini-card .info .card-subtitle .small-icon{
  font-size: 14px;
  margin-right: 3px;
}

/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
  width: 5px; /* Adjust this value to make it thinner */
  height: 5px; /* Adjust this value to make it shorter */
}

/* Set the background color of the scrollbar track */
::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, #f1f1f1, #e6e6e6);
}

/* Set the color and border radius of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 2px;
}

/* Add a hover effect to the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1;
}
