

.search-box {

  position: absolute;
  bottom: 60px;
  min-height: 500px;
  
  width: 615px;
  background-color: rgba(35, 47, 62, 1);
  border-radius: 10px;

}