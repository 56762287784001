.card-list{
    cursor: pointer;
    color:#4d5d73;
    margin-top: 0px;
}
.card-list:hover{
    box-shadow:2px 2px 10px 10px #d2d2d2;
}

.card-list .card-subtitle{
    font-size: 12px;
    text-transform: lowercase;
}
.card-list .card-title{
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
}

.card-list img {
  width: 100%;
  border-radius: 10px;
}

.card-list .dot{
    width: 12px;
}


.card-item-small{
    margin-top: 10px;
    cursor: pointer;
    overflow: hidden;
    color:#232f3e;
  }

  .card-item-small:hover{
  box-shadow:2px 2px 10px 10px #d2d2d2;
  }
  
  .card-item-small img{
    width:100%;
  }

  .card-item-img-small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 0; /* Set initial height to 0 */
    padding-top: 100%; /* Create a square by setting padding-top to 100% */
    position: relative; /* Required for absolute positioning */
  }
  
  .card-item-img-small img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain image aspect ratio and cover the container */
    position: absolute; /* Position the image within the square container */
    top: 0;
    left: 0;
  }
  