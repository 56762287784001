.product-rentals-calendar {
  text-align: center;
  margin-top: 20px;
}

.product-rentals-calendar .rdrDateDisplayWrapper {
  display: none; /* Hide the top date display */
}

.product-rentals-calendar .react-date-range-wrapper {
  display: inline-block;
}

.product-rentals-calendar .rdrCalendarWrapper {
  pointer-events: auto; /* Re-enable pointer events for the whole calendar */
}

.product-rentals-calendar .rdrMonthAndYearWrapper,
.product-rentals-calendar .rdrNextPrevButton,
.product-rentals-calendar .rdrMonth {
  pointer-events: auto; /* Ensure all parts of the calendar are interactive */
}

.product-rentals-calendar .rdrDay {
  pointer-events: none; /* Disable pointer events on individual days */
}

.product-rentals-calendar .rdrDay:hover {
  background: none; /* Remove hover effect on individual days */
}
