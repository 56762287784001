.toggle{
  display: block;
  position: relative;
  background: none;
  margin: 0 0 0.5em 0;
  float: left;
  }
.toggle input{
  opacity: 0;
}
.toggle label h2{
  position: absolute;
  top:12px;
  left:32px;
  font-weight: bold;
  font-size: 9px;
  color:#BDBDBD;
  text-shadow: 0px 2px 0px #f6f6f6;
  cursor: pointer;

}
.toggle label h1{
  position: absolute;
  top:13px;
  left:10px;
  font-weight: bold;
  font-size: 9px;
  color:#BDBDBD;
  text-shadow: 0px 2px 0px rgba(192,205,255 ,.15);
  cursor: pointer;
}
.toggle input + label:before{
  cursor: pointer;
  box-sizing: border-box;
  position:absolute;
  top:0.25em;
  left:0px;
  content:'';
  width:56px;
  height:26px;
  transition: background-color 0.4s ease;
  border-radius:70px;
  box-shadow: inset 7px 7px 16px #adadad;
}
.toggle input:checked + label:before{
  background: #385dff;
  box-shadow: inset 5px 5px 10px #2945db;
}
.toggle input + label:after{
  cursor: pointer;
box-sizing: border-box;
position: absolute;
top:8px;
left:8px;
width: 18px;
height: 18px;
content: '';
cursor: pointer;
background-color: #ebebeb;
border-radius: 500rem;
box-shadow: 2px 2px 2px #c2c2c2, 0px 0px 0px #ebebeb;
transition:transform 0.4s cubic-bezier(0.45,1.8,0.5,0.775);
}
.toggle input:checked + label:after{
  transform: translateX(1.45rem);

}


.footer{
  padding: 5px;
  background-color: #232f3e;
  color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .add-icon{
  font-size: 25px;
  color: #f3f3f3;
  cursor: pointer;
  
}

.footer .add-icon:hover{
  color: #61dafb;
}

