.searchText{
    padding-left: 10px;
}

.searchBar .backgroundText{
    background-image: url('../../../assets/images/loop.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 99% 8px;
}

.searchBar .backgroundTextMini{
    background-image: url('../../../assets/images/loop.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 95% 8px;
}

