.card{
    cursor: pointer;
    color:#4d5d73;
    margin-top: 0px;
    padding: 10px;
    position: relative;
    transition: all 0.3s ease;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.card:hover{
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.card .card-subtitle{
    font-size: 12px;
    text-transform: lowercase;
    display: flex;
    align-items: center;
}
.card .card-title{
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 5px;
}

.card .img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    aspect-ratio: 1;
    margin-bottom: 10px;
  
}
.card img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    border-radius: 10px;
  }
  

.card .fake{
    position:absolute;
    width:40px;
    right:10px;
    top:10px;
}
.card .dot{
    width: 12px;
}

.card .small-icon{
    font-size: 14px;
    margin-right: 3px;
}
